import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Fade from 'react-reveal/Fade'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share'

import Theme, { Mq } from '../themes/default'
import Layout from '../organisms/layout'
import { H4 } from '../atoms/heading'
import { SmallTitle } from '../atoms/text'
import { Row, Column } from '../atoms/grid'
import SEO from '../organisms/seo'
import { FooterHero } from '../molecules/footer-hero'
import BlogPostMeta from '../molecules/blog-post-meta'
import { IContentfulBlogPost } from '../interfaces'

interface IBlogPostDataProps {
  contentfulBlogPost: IContentfulBlogPost
}

interface IBlogPostProps extends RouteComponentProps {
  data: IBlogPostDataProps
  location: {
    href: string
  }
}

const BlogContentContainer = styled.div`
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;

  ${Mq.md} {
    padding: 5% 3%;
    margin: 0 auto;
    width: 90%;
  }
  ${Mq.xxl} {
    width: 80%;
    max-width: 1400px;
  }

  .SocialMediaShareButton {
    cursor: pointer;
  }
`

const BorderStyles = `
  width: 1px;
  height: 100%;
  background: ${rgba(Theme.colors.darkBlue, 0.1)};
  position: absolute;
  top: 0;
  display: none;
  z-index: -3;

  ${Mq.md} {
    display: block;
  }
`

const BorderLeft = styled.div`
  ${BorderStyles};
  left: 5%;

  ${Mq.xxl} {
    left: 15%;
  }
`

const BorderRight = styled.div`
  ${BorderStyles};
  right: 5%;

  ${Mq.xxl} {
    right: 15%;
  }
`

const BlogContentStyles = `
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.3;

    ${Mq.md} {
      font-size: 2.625rem;
    }
  }

  h2 {
    font-size: 1.35rem;
    margin-bottom: 1rem;
    line-height: 1.3;

    ${Mq.md} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 1.3;

    ${Mq.md} {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.3;

    ${Mq.md} {
      font-size: 1.3rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.25rem;

    ${Mq.md} {
      margin-bottom: 2rem;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }
  }

  blockquote p {
    background: ${Theme.colors.lightBrown};
    font-family: ${Theme.fonts.primary};
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 1.7;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    ${Mq.md} {
      padding: 2rem;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 2rem;
    }
  }

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    font-size: 1rem;
    line-height: 1.6;

    ${Mq.md} {
      padding-left: 2rem;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
      margin-bottom: 2rem;
    }

    li {
      margin-bottom: 0.75rem;

      ${Mq.md} {
        margin-bottom: 1rem;
      }
    }
  }

  a {
    color: ${Theme.colors.primary};
    word-break: break-word;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1.25rem;

    ${Mq.md} {
      margin-bottom: 2rem;
    }
  }
`

const ContentWrapper = styled.div`
  ${BlogContentStyles}
  width: 100%;
`

const BlogShareSticky = styled.div`
  margin-bottom: 2rem;
  width: 100%;

  ${Mq.md} {
    position: sticky;
    top: 6rem;
    margin-bottom: 0;
  }
`

const BlogAuthor = styled(H4)`
  margin-bottom: 1.5rem;

  ${Mq.md} {
    margin-bottom: 4rem;
  }
`

const PublishDate = styled(SmallTitle)`
  color: ${rgba(Theme.colors.darkBlue, 0.5)};
  margin-top: 1.5rem;
`

const SocialShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  .social-icon {
    width: 32px;
    height: 32px;

    ${Mq.md} {
      width: 40px;
      height: 40px;
    }
  }
`

const BlogPost = (props: IBlogPostProps) => {
  const { contentfulBlogPost } = props.data
  const url = props.location?.href || ''

  return (
    <Layout altBg={true} hideBorders={true}>
      <SEO
        description={contentfulBlogPost.seoDescription.seoDescription}
        image={contentfulBlogPost.featuredMedia.gatsbyImageData}
        keywords={contentfulBlogPost.seoKeywords}
        title={contentfulBlogPost.seoTitle || contentfulBlogPost.title}
      />
      <BlogPostMeta
        title={contentfulBlogPost.title}
        featuredImage={contentfulBlogPost.featuredMedia}
      />

      <BorderLeft />
      <BorderRight />

      <Fade bottom={true} distance={Theme.fadeDistance}>
        <BlogContentContainer>
          <Row flexWrap="wrap" justifyContent="center" alignItems="flex-start">
            <Column
              order={[2, 2, 2, 1]}
              width={[1, 1, 1, 0.25]}
              px={[2, 2, 2, 3]}
              mb={[4, 4, 4, 0]}
            >
              <BlogShareSticky>
                <SmallTitle>Author</SmallTitle>
                <BlogAuthor>{contentfulBlogPost.author.name}</BlogAuthor>

                <SmallTitle>Share</SmallTitle>
                <SocialShareContainer>
                  <FacebookShareButton url={url}>
                    <FacebookIcon className="social-icon" round size={40} />
                  </FacebookShareButton>
                  <TwitterShareButton url={url}>
                    <TwitterIcon className="social-icon" round size={40} />
                  </TwitterShareButton>
                  <LinkedinShareButton url={url}>
                    <LinkedinIcon className="social-icon" round size={40} />
                  </LinkedinShareButton>
                  <EmailShareButton url={url}>
                    <EmailIcon className="social-icon" round size={40} />
                  </EmailShareButton>
                </SocialShareContainer>
              </BlogShareSticky>
            </Column>

            <Column order={[1, 1, 1, 2]} width={[1, 1, 1, 0.75]} px={[2, 2, 3]}>
              <ContentWrapper
                dangerouslySetInnerHTML={{
                  __html: contentfulBlogPost.body.childMarkdownRemark.html,
                }}
              />
              <div>
                <PublishDate>
                  Updated: {contentfulBlogPost.publishDate}
                </PublishDate>
              </div>
            </Column>
          </Row>
        </BlogContentContainer>
      </Fade>

      <FooterHero />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MM/YYYY")
      author {
        name
        avatar {
          gatsbyImageData(width: 100, placeholder: BLURRED)
        }
      }
      featuredMedia {
        gatsbyImageData(width: 1400, layout: CONSTRAINED)
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      seoKeywords
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`

export default BlogPost
