import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

import { Row, Column } from '../atoms/grid'
import Theme, { Mq } from '../themes/default'
import { H1 } from '../atoms/heading'
import { SmallTitle } from '../atoms/text'
import { UnstyledGatsbyLink } from '../atoms/link'

import Waves from '../assets/images/waves.jpg'
import BackArrow from '../assets/images/back-arrow.svg'

interface IBlogPostMetaProps {
  featuredImage: {
    gatsbyImageData: IGatsbyImageData
  }
  title: string
}

const BlogHeroRow = styled(Row)`
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  ${Mq.sm} {
    flex-direction: row;
  }
`

const BlogHeroTextContainer = styled.div`
  background: ${(props) => props.theme.colors.darkBlue};
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto;
  transform: translateY(-1.5rem);

  ${Mq.xs} {
    padding: 2rem;
    transform: translateY(-2rem);
  }

  ${Mq.sm} {
    width: 95%;
    padding: 2.5rem;
    transform: translateY(-2.5rem);
  }

  ${Mq.md} {
    width: 90%;
    padding-right: 8rem;
    padding-left: 3rem;
    height: 300px;
    transform: translateY(-3rem);
  }

  ${Mq.xxl} {
    width: 85%;
  }
`

const BlogHeroLinkText = styled(SmallTitle)`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;

  &:before {
    content: '';
    background-image: url(${BackArrow});
    width: 10px;
    height: 20px;
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: bottom;
  }

  &:hover {
    text-decoration: underline;
  }

  ${Mq.sm} {
    margin-bottom: 0;

    &:before {
      margin-right: 1rem;
    }
  }
`

const BlogPostTitle = styled(H1)`
  color: ${Theme.colors.white};
  margin: 0;
  font-size: 1.8rem;
  text-align: center;

  ${Mq.xs} {
    font-size: 2rem;
  }

  ${Mq.sm} {
    font-size: 2.2rem;
    text-align: right;
  }

  ${Mq.md} {
    font-size: 3vw;
  }

  ${Mq.xxl} {
    font-size: 2.6rem;
  }
`

const HeroImageContainer = styled.div`
  width: 100%;
  height: 35vh;
  overflow: hidden;

  ${Mq.sm} {
    height: 40vh;
  }

  ${Mq.md} {
    height: 50vh;
  }
`

const WavesContainer = styled.div`
  background-image: url(${Waves});
  background-repeat: no-repeat;
  width: 100%;
  height: 40vh;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;

  ${Mq.sm} {
    height: 50vh;
  }

  ${Mq.md} {
    height: 60vh;
  }
`

const FeaturedImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const BlogPostMeta = (props: IBlogPostMetaProps) => {
  const { featuredImage, title } = props
  return (
    <div>
      <HeroImageContainer>
        <WavesContainer />
        <FeaturedImage image={featuredImage.gatsbyImageData} alt={title} />
      </HeroImageContainer>

      <Fade right={true} distance={Theme.fadeDistance}>
        <BlogHeroTextContainer>
          <BlogHeroRow>
            <UnstyledGatsbyLink to="/blog">
              <BlogHeroLinkText>View All</BlogHeroLinkText>
            </UnstyledGatsbyLink>
            <BlogPostTitle>{title}</BlogPostTitle>
          </BlogHeroRow>
        </BlogHeroTextContainer>
      </Fade>
    </div>
  )
}

export default BlogPostMeta
